import "./App.css";
import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Phonics from "./phonics/Phonics";
import Cards from "./components/Cards";
import { QueryClientProvider, QueryClient } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import Traits from "./components/Traits";
import EquipmentList from "./components/EquipmentList";
import BMGLanding from "./components/BMGLanding";

const LandingPage = () => {
  return <header className="App-header">This is Gilham Solutions</header>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});
const App = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route path={"/phonics"} component={Phonics} />
            <Route path={"/bmg/cards/:cardId?"} component={Cards} />
            <Route path={"/bmg/equipment/:cardId?"} component={EquipmentList} />
            <Route path={"/bmg/traits"} component={Traits} />
            <Route path={"/bmg"} component={BMGLanding} exact={true} />
            <Route component={LandingPage} />
          </Switch>
        </Router>
      </QueryClientProvider>
    </div>
  );
};

export default App;
