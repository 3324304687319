import styles from './Card.module.scss';
import {ReactComponent as Target} from './resources/ic_target_icon.svg';
import {ReactComponent as Ammo} from './resources/ic_ammo_icon.svg';
import {ReactComponent as Blood} from './resources/ic_blood_icon.svg';
import {ReactComponent as Stun} from './resources/ic_stun_icon.svg';

const convertDamage = (damageArray) => {
  return <div>
    {damageArray.map(
        d =>
            [...Array(d.count)].map(
                _ => d.damage_type_id === 1 ? <Blood/> : <Stun/>))}
  </div>;
};

const Weapon = ({weapon = {}, traits = []}) => {
  if (!weapon) return null;
  return <div className={styles.weaponBar}>
    <div className={styles.weapon}>
      <div>{weapon.name}</div>
      <div>{weapon.damage ? convertDamage(weapon.damage) : ''}</div>
      <div>{weapon.rate_of_fire
          ? <>{weapon.rate_of_fire}<Target/></>
          : '-'}</div>
      <div>{weapon.ammunition ? <>{weapon.ammunition}<Ammo/></> : '-'}</div>
      <div className={styles.weaponTrait}>
        {weapon.traits?.map(trait =>
            trait.alternate_name ||
            traits.find(tr => tr.id === trait.trait_id)?.name,
        ).join(' / ')}
      </div>
    </div>
  </div>;
};
export default Weapon;
