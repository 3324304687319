import backOfCard from "./resources/backOfCard.png";
import styles from "./Card.module.scss";
import { replaceSpecialIcons } from "./Utils";
import { useLayoutEffect, useRef, useState } from "react";

const Back = ({ character, traits }) => {
  const traitBlockRef = useRef();
  const [fontSize, setFontSize] = useState(2);

  useLayoutEffect(() => {
    const { current } = traitBlockRef;

    if (current.scrollHeight > current.clientHeight)
      setFontSize(fontSize - 0.05);
  }, [fontSize, traitBlockRef, character.id]);

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage: `url('${backOfCard}')`,
      }}
      className={styles.card}
    >
      <div className={styles.backOfCard} ref={traitBlockRef}>
        {character.traits.map((trait) => {
          const foundTrait = traits.find((tr) => tr.id === trait.trait_id);
          return (
            <div
              className={styles.trait}
              id={`${trait.trait_id}-${trait.name}-${character.id}`}
            >
              <span
                className={styles.backTraitName}
                style={{ fontSize: `${fontSize + 0.1}rem` }}
              >
                {replaceSpecialIcons(trait.alternate_name || foundTrait.name)}
                {" - "}
              </span>
              <span
                className={styles.traitDescription}
                style={{ fontSize: `${fontSize}rem` }}
              >
                {replaceSpecialIcons(foundTrait.description)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Back;
