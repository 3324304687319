import styles from "./Card.module.scss";
import { ReactComponent as Willpower } from "./resources/ic_background_willpower.svg";
import { ReactComponent as Endurance } from "./resources/ic_background_endurance.svg";
import { ReactComponent as Henchman } from "./resources/ic_rank_henchman.svg";
import { ReactComponent as Leader } from "./resources/ic_rank_leader.svg";
import { ReactComponent as Sidekick } from "./resources/ic_rank_sidekick.svg";
import { ReactComponent as FreeAgent } from "./resources/ic_rank_free_agent.svg";
import { ReactComponent as Attack } from "./resources/ic_background_attack.svg";
import { ReactComponent as Defense } from "./resources/ic_background_defense.svg";
import { ReactComponent as Movement } from "./resources/ic_background_movement.svg";
import { ReactComponent as Strength } from "./resources/ic_background_strength.svg";
import { ReactComponent as Special } from "./resources/ic_background_special.svg";
import { ReactComponent as RankVehicleIcon } from "./resources/ic_rank_vehicle.svg";
import Weapon from "./Weapon";
import { replaceSpecialIcons } from "./Utils";

const Card = ({ character, weapons, traits, affiliations }) => {
  const RANKS = [
    " ",
    <Leader />,
    <Sidekick />,
    <FreeAgent />,
    <RankVehicleIcon />,
    <Henchman />,
  ];

  return (
    <div className={styles.front}>
      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage: `url('${character.background}')`,
        }}
        className={styles.card}
      >
        <div className={styles.topBar}>
          <div className={styles.stacked + " " + styles.left}>
            <span className={styles.alias}>{character.alias}</span>
            <span>
              {character.name} / {character.bases_size}
            </span>
          </div>
          <div>
            <span className={styles.sideways}>Rank</span>
          </div>
          <div className={styles.aff}>
            {character.rank_ids.map((rank) => RANKS[rank])}
          </div>
          <div>
            <span className={styles.sideways}>Aff</span>
          </div>
          <div className={styles.aff}>
            {character.affiliations.map((aff) => {
              let affiliation = affiliations.find(
                (a) => a.id === aff.affiliation_id
              );
              if (affiliation)
                return (
                  <img
                    src={affiliation.icon}
                    alt={affiliation.name}
                    className={styles.affIcon}
                  />
                );
              return null;
            })}
          </div>
          <div>
            <span className={styles.sideways}>Riv</span>
          </div>
          <div className={styles.aff}>
            {character.rival_affiliation_ids.map((aff) => {
              let affiliation = affiliations.find((a) => a.id === aff);
              if (affiliation)
                return (
                  <img
                    src={affiliation.icon}
                    alt={affiliation.name}
                    className={styles.affIcon}
                  />
                );
              return null;
            })}
          </div>
          <div className={styles.stacked}>
            <span className={styles.reputation}>
              {character.reputation} REP
            </span>
            <span className={styles.reputation}>{character.funding} $</span>
          </div>
        </div>
        <div className={styles.statBlock}>
          <div />
          <div className={styles.stats}>
            <div className={styles.bigStats}>
              <div />
              <div className={styles.statContainer}>
                <Willpower className={styles.statBackground} />
                <span
                  className={
                    character.willpower > 9
                      ? styles.imageDoubleText
                      : styles.imageText
                  }
                >
                  {character.willpower}
                </span>
                <span className={styles.small}>Willpower</span>
              </div>
              <div className={styles.statContainer}>
                <Endurance className={styles.statBackground} />
                <span
                  className={
                    character.endurance > 9
                      ? styles.imageDoubleText
                      : styles.imageText
                  }
                >
                  {character.endurance}
                </span>
                <span className={styles.small}>Endurance</span>
              </div>
              <div />
            </div>
            <div className={styles.mediumStats}>
              <div />
              <div className={styles.statContainer}>
                <Attack className={styles.statBackground} />
                <span className={styles.attackText}>{character.attack}</span>
              </div>
              <div className={styles.statContainer}>
                <Defense className={styles.statBackground} />
                <span className={styles.attackText}>{character.defense}</span>
              </div>
              <div />
            </div>
            <div className={styles.tinyStats}>
              <div className={styles.statContainer}>
                <Strength className={styles.statBackground} />
                <span className={styles.tinyText}>{character.strength}+</span>
              </div>
              <div className={styles.statContainer}>
                <Movement className={styles.statBackground} />
                <span className={styles.tinyText}>{character.movement}</span>
              </div>
              <div className={styles.statContainer}>
                <Special className={styles.statBackground} />
                <span className={styles.tinyText}>{character.special}</span>
              </div>
            </div>
          </div>
        </div>
        <Weapon
          traits={traits}
          weapon={weapons.find(
            (weapon) => weapon.id === character.weapon_ids[0]
          )}
        />
        <Weapon
          traits={traits}
          weapon={weapons.find(
            (weapon) => weapon.id === character.weapon_ids[1]
          )}
        />
        <div className={styles.traitBlock}>
          {character.traits.map((trait) => {
            return (
              <div className={styles.traitName}>
                {replaceSpecialIcons(
                  trait.alternate_name ||
                    traits.find((tr) => tr.id === trait.trait_id)?.name
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Card;
