import { useQuery } from "react-query";
import axios from "axios";
import Equipment from "./Equipment";
import styles from "./Equip.module.scss";
import { Multiselect } from "multiselect-react-dropdown";
import { useState } from "react";

const EquipmentList = () => {
  const url = "https://gilham.solutions/card-api";
  const { isLoading, data } = useQuery(
    url,
    () => axios.get(url).then((resp) => resp.data),
    { refetchOnWindowFocus: false }
  );
  const [affIds, setAffIds] = useState([3]);
  const {
    traits = [],
    equipment = [],
    affiliations = [],
    characters = [],
  } = data || {};
  const onSelect = (list, select) => {
    setAffIds(list.map((char) => char.id));
  };
  const onRemove = (list, select) => {
    setAffIds(list.map((char) => char.id));
  };
  const selectedValues = affiliations
    .map((aff) => ({
      ...aff,
      display: `${aff.name}`,
    }))
    .filter((aff) => affIds.includes(aff.id));

  const options = [...affiliations].map((aff) => ({
    ...aff,
    display: `${aff.name}`,
  }));
  return (
    <div>
      <div className={styles.search}>
        <div className={styles.searchBar}>
          <Multiselect
            options={options}
            displayValue="display"
            selectedValues={selectedValues}
            onSelect={onSelect}
            onRemove={onRemove}
            showCheckbox
          />
        </div>
      </div>
      {isLoading ? (
        <div>LOADING</div>
      ) : (
        <div className={styles.equipList}>
          {equipment
            .map((aff) => ({
              ...aff,
              display: `${aff.name}`,
            }))
            .filter((equip) =>
              affIds.includes(equip.required_affiliation_ids[0])
            )
            .map((equip) => (
              <Equipment
                equip={equip}
                traits={traits}
                affiliations={affiliations}
                characters={characters}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default EquipmentList;
