import { useQuery } from "react-query";
import axios from "axios";
import Card from "./Card";
import { useEffect, useRef, useState } from "react";
import styles from "./Card.module.scss";
import { useParams } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Back from "./Back";

const Cards = () => {
  const url = "https://gilham.solutions/card-api";
  const { isLoading, data } = useQuery(
    url,
    () => axios.get(url).then((resp) => resp.data),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    document.title = "Batman Cards";
  }, []);

  const { cardId = "" } = useParams();
  const initalCards = cardId.split(",");
  const [charIds, setCharIds] = useState(initalCards.map((id) => parseInt(id)));
  const [showCards, setShowCards] = useState(true);
  const [showBack, setShowBack] = useState(true);
  const ref = useRef();
  const onSelect = (list, select) => {
    setCharIds(list.map((char) => char.id));
  };
  const onRemove = (list, select) => {
    setCharIds(list.map((char) => char.id));
  };
  const copyLink = () => {
    navigator.clipboard.writeText(
      window.location.host + "/bmg/cards/" + charIds
    );
  };
  const toggleCards = () => setShowCards(!showCards);
  const toggleBack = () => setShowBack(!showBack);
  const {
    traits = [],
    weapons = [],
    affiliations = [],
    characters = [],
  } = data || {};
  const characterDetails = characters
    .map((char) => ({ ...char, display: `${char.alias} (${char.id})` }))
    .filter((char) => charIds.includes(char.id));

  const options = [...characters]
    .map((char) => ({ ...char, display: `${char.alias} (${char.id})` }))
    .reverse();
  return (
    <div className={styles.app}>
      <div className={styles.search}>
        <div className={styles.searchBar}>
          <Multiselect
            options={options}
            displayValue="display"
            selectedValues={characterDetails}
            onSelect={onSelect}
            onRemove={onRemove}
            showCheckbox
          />
        </div>
        <button onClick={toggleCards}>
          {showCards ? "Hide Cards" : "Show Cards"}
        </button>
        <button onClick={toggleBack}>
          {showBack ? "Hide Backs" : "Show Backs"}
        </button>
        <button onClick={copyLink}>Share</button>
      </div>
      {isLoading ? (
        <div>LOADING</div>
      ) : (
        <div className={styles.characters} ref={ref}>
          {characterDetails.map((char) => (
            <>
              {showCards ? (
                <div className={styles.half}>
                  <Card
                    character={char}
                    traits={traits}
                    weapons={weapons}
                    affiliations={affiliations}
                  />
                </div>
              ) : null}
              {showBack ? (
                <div className={styles.half} id={`${char.id}-back`}>
                  <Back traits={traits} character={char} />
                </div>
              ) : null}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default Cards;
