import { useEffect, useState } from "react";
import classification from "./wordCategories.json";

const classifyWords = (sentence) => {
  const words = sentence.toLowerCase().split(" ");

  return words
    .filter((word) => word)
    .reduce((result, word) => {
      const phase =
        Object.keys(classification).find((key) =>
          classification[key].find((words) => words.toLowerCase() === word)
        ) || "Unknown Phase";
      result[phase] = result[phase] || [];

      result[phase].push(word);

      return result;
    }, {});
};

const Phonics = () => {
  const [sentence, setSentence] = useState("");
  const [breakdown, setBreakdown] = useState({});
  useEffect(() => {
    setBreakdown(classifyWords(sentence));
  }, [sentence]);

  return (
    <div
      style={{
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "center",
      }}
    >
      <h1>Enter the sentence below for a breakdown</h1>
      <textarea
        value={sentence}
        onChange={(e) => {
          setSentence(e.target.value);
        }}
        style={{ "margin-bottom": "1vh", width: "80%" }}
      />
      <ul>
        {Object.keys(breakdown).map((key) => (
          <li>
            {key} - {breakdown[key].join(" ")}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Phonics;
