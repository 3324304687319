import reactStringReplace from "react-string-replace";
import { ReactComponent as SpecialIcon } from "./resources/ic_special_icon.svg";
import { ReactComponent as BloodIcon } from "./resources/ic_blood_icon.svg";
import { ReactComponent as StunIcon } from "./resources/ic_stun_icon.svg";
import { ReactComponent as AttackIcon } from "./resources/ic_plus_att_icon.svg";
import { ReactComponent as AttackDownIcon } from "./resources/ic_less_att_icon.svg";
import { ReactComponent as Mov2Icon } from "./resources/ic_mov_2_icon.svg";
import { ReactComponent as Mov4Icon } from "./resources/ic_mov_4_icon.svg";
import { ReactComponent as Mov6Icon } from "./resources/ic_mov_6_icon.svg";
import { ReactComponent as MovDown2Icon } from "./resources/ic_mov_minus_2_icon.svg";
import { ReactComponent as MovDown4Icon } from "./resources/ic_mov_minus_4_icon.svg";
import { ReactComponent as MovDown6Icon } from "./resources/ic_mov_minus_6_icon.svg";
import { ReactComponent as CrownIcon } from "./resources/ic_crown_icon.svg";
import { ReactComponent as DefUpIcon } from "./resources/ic_plus_def_icon.svg";
import { ReactComponent as DefDownIcon } from "./resources/ic_less_def_icon.svg";
import { ReactComponent as AffBaneIcon } from "./resources/ic_aff_bane_icon.svg";
import { ReactComponent as AffBatmanIcon } from "./resources/ic_aff_batman_icon.svg";
import { ReactComponent as AffMrFreezeIcon } from "./resources/ic_aff_mrfreeze_icon.svg";
import { ReactComponent as RankSidekickIcon } from "./resources/ic_rank_sidekick.svg";
import { ReactComponent as RankHenchmanIcon } from "./resources/ic_rank_henchman.svg";
import { ReactComponent as RankFreeagentIcon } from "./resources/ic_rank_free_agent.svg";
import { ReactComponent as RankLeaderIcon } from "./resources/ic_rank_leader.svg";
import { ReactComponent as RankVehicleIcon } from "./resources/ic_rank_vehicle.svg";
import { ReactComponent as OtProtIcon } from "./resources/ic_ot_protection_icon.svg";
import { ReactComponent as OtMenaceIcon } from "./resources/ic_ot_menace_icon.svg";
import { ReactComponent as OtViolenceIcon } from "./resources/ic_ot_violence_icon.svg";
import { ReactComponent as OtControlIcon } from "./resources/ic_ot_control_icon.svg";

const icons = {
  SPECIAL_ICON: <SpecialIcon />,
  BLOOD_ICON: <BloodIcon />,
  STUN_ICON: <StunIcon />,
  "+ATT_ICON": <AttackIcon />,
  "-ATT_ICON": <AttackDownIcon />,
  "+DEF_ICON": <DefUpIcon />,
  "-DEF_ICON": <DefDownIcon />,
  "MOV+2_ICON": <Mov2Icon />,
  "MOV+4_ICON": <Mov4Icon />,
  "MOV+6_ICON": <Mov6Icon />,
  "MOV-2_ICON": <MovDown2Icon />,
  "MOV-4_ICON": <MovDown4Icon />,
  "MOV-6_ICON": <MovDown6Icon />,
  CROWN_ICON: <CrownIcon />,
  AFF_BANE_ICON: <AffBaneIcon />,
  AFF_BATMAN_ICON: <AffBatmanIcon />,
  AFF_MRFREEZE_ICON: <AffMrFreezeIcon />,
  RANK_SIDEKICK_ICON: <RankSidekickIcon />,
  RANK_HENCHMAN_ICON: <RankHenchmanIcon />,
  RANK_FREEAGENT_ICON: <RankFreeagentIcon />,
  RANK_LEADER_ICON: <RankLeaderIcon />,
  RANK_VEHICLE_ICON: <RankVehicleIcon />,
  OT_CONTROL_ICON: <OtControlIcon />,
  OT_MENACE_ICON: <OtMenaceIcon />,
  OT_VIOLENCE_ICON: <OtViolenceIcon />,
  OT_PROTECTION_ICON: <OtProtIcon />,
};

export const replaceSpecialIcons = (string) => {
  let iconsReplaced = reactStringReplace(string, /\{(.*?)}/g, (match) => {
    return icons[match] || match;
  });
  iconsReplaced = reactStringReplace(iconsReplaced, /(\r\n)+/, (match) => {
    return <br />;
  });
  return iconsReplaced;
};
