import { useQuery } from "react-query";
import axios from "axios";
import { replaceSpecialIcons } from "./Utils";
import styles from "./Traits.module.scss";
import { useEffect } from "react";

const Traits = () => {
  useEffect(() => {
    document.title = "Batman Traits";
  }, []);
  const url = "https://gilham.solutions/card-api";
  const { data } = useQuery(
    url,
    () => axios.get(url).then((resp) => resp.data),
    { refetchOnWindowFocus: false }
  );
  const { traits = [] } = data || {};
  return (
    <div>
      <div className={styles.fullTraits}>
        {traits.map((trait) => {
          return (
            <div className={styles.traitBlock}>
              <div className={styles.traitName}>
                {replaceSpecialIcons(trait.name)}
              </div>
              <div className={styles.traitDescription}>
                {replaceSpecialIcons(trait.description)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Traits;
