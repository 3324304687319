import styles from "./Equip.module.scss";
import { replaceSpecialIcons } from "./Utils";

const Equipment = ({ equip, affiliations }) => {
  return (
    <div className={styles.equip}>
      {equip.image ? (
        <div className={styles.replacementImage}>
          <img src={equip.image} alt={equip.name} />
        </div>
      ) : (
        <>
          <div className={styles.topBar}>
            <span>{equip.name}</span>
            <div className={styles.aff}>
              {equip.required_affiliation_ids.map((aff) => {
                let affiliation = affiliations.find((a) => a.id === aff);
                if (affiliation)
                  return (
                    <img
                      src={affiliation.icon}
                      alt={affiliation.name}
                      className={styles.affIcon}
                    />
                  );
                return null;
              })}
            </div>
            <div className={styles.stacked}>
              <span>{equip.reputation} REP</span>
              <span>{equip.funding} $</span>
            </div>
          </div>
          <div className={styles.desc}>
            {replaceSpecialIcons(equip.description)}
          </div>
        </>
      )}
    </div>
  );
};
export default Equipment;
