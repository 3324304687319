import { Link } from "react-router-dom";

const BMGLanding = () => {
  return (
    <div style={{ display: "grid" }}>
      <h1>Batman Miniature Game</h1>
      <div>
        This is a fan project for{" "}
        <a href="https://www.knightmodels.com/en/batman-miniature-game-home.html">
          Knight Models&#8482;
        </a>{" "}
        batman miniature game. This just grabs the data that drives the current
        mobile app and just displays it
      </div>
      <Link to="/bmg/cards">Cards</Link>
      <Link to="/bmg/traits">Traits</Link>
      <Link to="/bmg/equipment">Equipment</Link>
    </div>
  );
};
export default BMGLanding;
